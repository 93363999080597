import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import "react-image-crop/dist/ReactCrop.css";
import { notification } from "../../const/notification";
import backgroundImage from "../../assets/background.jpg";

const details = navigator.userAgent;
const regExp = /android|iphone/i;
const isMobileDevice = regExp.test(details);

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: isMobileDevice ? 45 : 50,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const Post = () => {
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const myCanvas = useRef();
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [cropModal, setCropModal] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [name, setName] = useState("");
  const [fileType, setFileType] = useState(0);

  useEffect(() => {
    const context = myCanvas.current.getContext("2d");
    var sources = {
      image2: backgroundImage,
    };
    loadImages(sources, function (images) {
      let arr = name?.split(" ");
      for (var i = 0; i < arr.length; i++)
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      const name1 = arr.join(" ");
      context.drawImage(images.image2, 0, 0, 1080, 1350);

      context.font = "600 30px sans-serif";
      context.fillStyle = "#3d2217";
      context.textAlign = "center";
      context.textBaseline = "middle";
      wrapText(context, name1, 732.5 + 264 / 2, 693.5, 264, 50, 153);
    });
  }, []);

  // function wrapText(context, text, x, y, maxWidth, lineHeight) {
  //   var words = text.split(" ");
  //   var line = "";

  //   for (var n = 0; n < words.length; n++) {
  //     var testLine = line + words[n] + " ";
  //     var metrics = context.measureText(testLine);
  //     var testWidth = metrics.width;
  //     if (testWidth > maxWidth && n > 0) {
  //       context.fillText(line, x, y);
  //       line = words[n] + " ";
  //       y += lineHeight;
  //     } else {
  //       line = testLine;
  //     }
  //   }
  //   context.fillText(line, x, y);
  // }

  function wrapText(context, text, x, y, maxWidth, lineHeight, maxHeight) {
    var words = text.split(" ");
    var line = "";
    var lines = [];

    // Step 1: Create lines first (but don't draw yet) to calculate the total text height
    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + " ";
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        lines.push(line);
        line = words[n] + " ";
      } else {
        line = testLine;
      }
    }
    lines.push(line); // Push the last line

    // Step 2: Calculate total height of the wrapped text block
    var totalTextHeight = lines.length * lineHeight;

    // Step 3: Adjust y position to vertically center the text block
    var startY = y + (maxHeight - totalTextHeight) / 2 + lineHeight / 2;

    // Step 4: Now draw the text lines at the horizontally and vertically centered position
    for (var i = 0; i < lines.length; i++) {
      context.fillText(lines[i], x, startY);
      startY += lineHeight;
    }
  }

  function onSelectFile(e) {
    setCropModal(true);
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1 / 1));
  }

  function handleChange() {
    setCropModal(!cropModal);
    const context = myCanvas.current.getContext("2d");
    var sources = { image2: backgroundImage };
    if (imagePreview) sources.image1 = imagePreview;
    if (logoPreview) sources.image3 = logoPreview;
    if (!fileType) {
      setImagePreview(previewCanvasRef.current.toDataURL("image/png"));
      sources.image1 = previewCanvasRef.current.toDataURL("image/png");
      // } else {
      //   setLogoPreview(previewCanvasRef.current.toDataURL("image/png"));
      //   sources.image3 = previewCanvasRef.current.toDataURL("image/png");
    }

    loadImages(sources, function (images) {
      let arr = name?.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const name1 = arr.join(" ");

      context.drawImage(images.image2, 0, 0, 1080, 1350);
      context.save();

      // if (images?.image3) {
      //   const img = images?.image3;
      //   const imgAspectRatio = img.width / img.height;
      //   const targetAspectRatio = 264 / 153;

      //   let drawWidth, drawHeight, offsetX, offsetY;
      //   if (imgAspectRatio > targetAspectRatio) {
      //     drawWidth = 264;
      //     drawHeight = 264 / imgAspectRatio;
      //     offsetX = 732.5;
      //     offsetY = 693.5 + (153 - drawHeight) / 2;
      //   } else {
      //     drawWidth = 153 * imgAspectRatio;
      //     drawHeight = 153;
      //     offsetX = 732.5 + (264 - drawWidth) / 2;
      //     offsetY = 693.5;
      //   }
      //   context.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
      // }
      if (images?.image1) {
        context.beginPath();
        context.arc(865, 460, 154, 0, Math.PI * 2, false);
        context.strokeStyle = "#3d2217";
        context.stroke();
        context.clip();
        context.drawImage(images.image1, 711, 306, 308, 308);
      }
      context.restore();
      context.font = "600 30px sans-serif";
      context.fillStyle = "#3d2217";
      context.textAlign = "center";
      context.textBaseline = "middle";
      wrapText(context, name1, 732.5 + 264 / 2, 693.5, 264, 50, 153);
    });
  }

  function onNameChange(e) {
    let arr = e.target.value.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const name1 = arr.join(" ");
    const context = myCanvas.current.getContext("2d");

    var sources = { image2: backgroundImage };
    if (imagePreview) sources.image1 = imagePreview;
    // if (logoPreview) sources.image3 = logoPreview;

    loadImages(sources, function (images) {
      context.drawImage(images.image2, 0, 0, 1080, 1350);
      context.save();

      // if (images?.image3) {
      //   const img = images?.image3;
      //   const imgAspectRatio = img.width / img.height;
      //   const targetAspectRatio = 264 / 153;

      //   let drawWidth, drawHeight, offsetX, offsetY;
      //   if (imgAspectRatio > targetAspectRatio) {
      //     drawWidth = 264;
      //     drawHeight = 264 / imgAspectRatio;
      //     offsetX = 732.5;
      //     offsetY = 693.5 + (153 - drawHeight) / 2;
      //   } else {
      //     drawWidth = 153 * imgAspectRatio;
      //     drawHeight = 153;
      //     offsetX = 732.5 + (264 - drawWidth) / 2;
      //     offsetY = 693.5;
      //   }
      //   context.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
      // }
      if (images?.image1) {
        context.beginPath();
        context.arc(865, 460, 154, 0, Math.PI * 2, false);
        context.strokeStyle = "#3d2217";
        context.stroke();
        context.clip();
        context.drawImage(images.image1, 711, 306, 308, 308);
      }
      context.restore();
      context.font = "600 30px sans-serif";
      context.fillStyle = "#3d2217";
      context.textAlign = "center";
      // wrapText(context, name1, 865, 725, 264, 50);
      context.textBaseline = "middle";
      wrapText(context, name1, 732.5 + 264 / 2, 693.5, 264, 50, 153);
    });
  }

  function loadImages(sources, callback) {
    var images = {};
    var loadedImages = 0;
    var numImages = 0;
    for (var src in sources) {
      numImages++;
    }
    for (var src in sources) {
      let width;
      let height;
      images[src] = new Image();

      images[src].crossOrigin = "anonymous";
      images[src].onload = function () {
        if (++loadedImages >= numImages) {
          callback(images);
        }
      };
      images[src].src = sources[src];
    }
  }

  const DownloadCanvasAsImage = async (data) => {
    if (!imagePreview) {
      notification({
        type: "error",
        message: "Please Upload Image",
      });
    } else {
      let downloadLink = document.createElement("a");
      let canvas = myCanvas.current;
      let url;
      let dataURL;
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        downloadLink.setAttribute("download", "TreasureHunt.jpeg");
        dataURL = canvas.toDataURL("image/jpeg");
        url = dataURL.replace(
          /^data:image\/jpeg/,
          "data:application/octet-stream"
        );
        // notification({
        //   type: "info",
        //   message:
        //     "Long press on the image and choose 'Save Image' to save it to your gallery.",
        // });
      } else {
        downloadLink.setAttribute("download", "TreasureHunt.png");
        dataURL = canvas.toDataURL("image/png");
        url = dataURL.replace(
          /^data:image\/png/,
          "data:application/octet-stream"
        );
      }
      downloadLink.setAttribute("href", url);
      downloadLink.click();

      // // Show the image on the screen so the user can save it manually
      // const imageElement = document.createElement("img");
      // imageElement.src = dataURL;
      // // imageElement.style.width = "100%"; // Set image size as needed
      // document.body.appendChild(imageElement);
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        console.log(
          "###",
          imgRef.current,
          previewCanvasRef.current,
          completedCrop
        );
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  return (
    <div className="card m-1 p-2" style={{ maxWidth: "700px" }}>
      <div className="row d-flex align-items-end mb-2">
        <div className="col-sm-6 mb-2">
          <label for="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control form-control-sm"
            id="name"
            placeholder="Name"
            value={name}
            onChange={(e) => {
              onNameChange(e);
              setName(e?.target?.value);
            }}
          />
        </div>
        <div className="col-sm-4 mb-2">
          <label for="inputFile" className="form-label">
            Upload Your Photo
          </label>
          <input
            className="form-control form-control-sm"
            id="inputFile"
            type="file"
            name="fileInput"
            accept="image/*"
            onChange={(e) => {
              setFileType(0);
              onSelectFile(e);
            }}
          />
        </div>

        {/* <div className="col-sm-4 mb-2">
          <label for="inputFile" className="form-label">
            Upload Logo
          </label>
          <input
            className="form-control form-control-sm"
            id="inputFile"
            type="file"
            name="fileInput"
            accept="image/*"
            onChange={(e) => {
              setFileType(1);
              onSelectFile(e);
            }}
          />
        </div> */}
        <div className="col-sm-2 mb-2 text-center">
          <Button
            type="submit"
            className="btn btn-sm btn-dark"
            onClick={DownloadCanvasAsImage}
          >
            Download
          </Button>
        </div>
        <small className="text-danger fw-bold">
          * Please download the post and share on your social media.
        </small>
      </div>

      <div>
        <canvas
          ref={myCanvas}
          width={1080}
          height={1350}
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "100%",
            width: "100%",
          }}
        />
      </div>

      <Modal show={cropModal}>
        <Modal.Header style={{ display: "block" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
              marginRight: "10px",
              marginLeft: "5px",
            }}
          >
            <div>Set your {fileType ? "Logo" : "Image"} </div>
            <Button className="btn btn-sm btn-dark" onClick={handleChange}>
              Save
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={fileType ? "" : 1 / 1}
              circularCrop={!fileType}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop?.width,
                height: completedCrop?.height,
              }}
            />
            {/* )} */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Post;
